#dynamic-tab {
  .div-footer-panel {
    text-transform: uppercase;
    margin: 8px 16px 0 16px;
    font-style: italic;
    font-size: 11px;
    color: #2D97D7;
    display: flex;
    justify-content: flex-end;

    .date-filter {
      min-width: 300px;
    }
  }

  .data-in-progress {
    position: relative;
  }

  .action-icon {
    display: inline;
  }
}
