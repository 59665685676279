@import "colors.less";

.status-color,
.severity-warning,
.single-selection-detail-value {
  color: @text;
}

.quality-poor {
  color: @redStatus;
}

.quality-fair {
  color: @yellowStatus;

}

.quality-good {
  color: @greenStatus;
}

.quality-undefined,
.quality-unknown {
  color: @grayStatus;
}

.severity-clear,
.severity-cleared,
.status-ok{
  color: @greenStatus;
}

.severity-minor,
.status-minor{
  color: @yellowStatus;
}

.severity-major,
.status-warning{
  color: @orangeStatus;
}

.severity-critical,
.status-error {
  color: @redStatus;
}

.severity-indeterminate,
.status-unmonitored{
  color: @grayStatus;
}

.severity-warning{
  color: @blueStatus;
}

.status-journal {
  color: @purpleStatus;
}
