@import "./colors.less";

.mat-mdc-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-mdc-badge-small .mat-mdc-badge-content {
  font-size: 9px
}

.mat-mdc-badge-large .mat-mdc-badge-content {
  font-size: 24px
}

.mat-mdc-h1, .mat-mdc-headline, .mat-mdc-typography h1 {

  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-mdc-h2, .mat-mdc-title, .mat-mdc-typography h2 {

  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-mdc-h3, .mat-mdc-subheading-2, .mat-mdc-typography h3 {

  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-mdc-h4, .mat-mdc-subheading-1, .mat-mdc-typography h4 {

  letter-spacing: normal;
  margin: 0 0 16px
}

.mat-mdc-h5, .mat-mdc-typography h5 {

  margin: 0 0 12px
}

.mat-mdc-h6, .mat-mdc-typography h6 {

  margin: 0 0 12px
}

.mat-mdc-body-strong, .mat-mdc-body-2 {

  letter-spacing: normal
}

.mat-mdc-body, .mat-mdc-body-1, .mat-mdc-typography {

  letter-spacing: normal
}

.mat-mdc-body p, .mat-mdc-body-1 p, .mat-mdc-typography p {
  margin: 0 0 12px
}

.mat-mdc-small, .mat-mdc-caption {
  letter-spacing: normal
}

.mat-mdc-display-4, .mat-mdc-typography .mat-mdc-display-4 {
  letter-spacing: -0.05em;
  margin: 0 0 56px
}

.mat-mdc-display-3, .mat-mdc-typography .mat-mdc-display-3 {
  letter-spacing: -0.02em;
  margin: 0 0 64px
}

.mat-mdc-display-2, .mat-mdc-typography .mat-mdc-display-2 {
  letter-spacing: -0.005em;
  margin: 0 0 64px
}

.mat-mdc-display-1, .mat-mdc-typography .mat-mdc-display-1 {
  letter-spacing: normal;
  margin: 0 0 64px
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text, .mdc-button {
  font-size: 1rem !important;
}

.mat-mdc-bottom-sheet-container {

  letter-spacing: normal
}

.mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-icon-button, .mat-mdc-stroked-button, .mat-mdc-flat-button, .mat-mdc-fab, .mat-mdc-mini-fab {;

  font-weight: 500
}

.mat-mdc-button-toggle {
}

.mat-mdc-card {
}

.mat-mdc-card-title {
  font-size: 24px;
  font-weight: 500
}

.mat-mdc-card-header .mat-mdc-card-title {
  font-size: 20px
}

.mat-mdc-card-subtitle, .mat-mdc-card-content {
}

.mat-mdc-checkbox {
}

.mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
  line-height: 24px
}

.mat-mdc-chip {

  font-weight: 500
}

.mat-mdc-chip .mat-mdc-chip-trailing-icon.mat-mdc-icon, .mat-mdc-chip .mat-mdc-chip-remove.mat-mdc-icon {
  font-size: 18px
}

.mat-mdc-table {
}

.mat-mdc-header-cell {
  font-size: 12px;
  font-weight: 500
}

.mat-mdc-cell, .mat-mdc-footer-cell {
}

.mat-mdc-calendar {
}

.mat-mdc-calendar-body {
  font-size: 13px
}

.mat-mdc-calendar-body-label, .mat-mdc-calendar-period-button {

  font-weight: 500
}

.mat-mdc-calendar-table-header th {
  font-size: 11px;
  font-weight: 400
}

.mat-mdc-dialog-title {

  letter-spacing: normal
}

.mat-mdc-expansion-panel-header {;
  font-size: 15px;
  font-weight: 400
}

.mat-mdc-expansion-panel-content {

  letter-spacing: normal
}

.mat-mdc-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;;
  letter-spacing: normal
}

.mat-mdc-form-field-wrapper {
  padding-bottom: 1.34375em
}

.mat-mdc-form-field-prefix .mat-mdc-icon, .mat-mdc-form-field-suffix .mat-mdc-icon {
  font-size: 150%;
  line-height: 1.125
}

.mat-mdc-form-field-prefix .mat-mdc-icon-button, .mat-mdc-form-field-suffix .mat-mdc-icon-button {
  height: 1.5em;
  width: 1.5em
}

.mat-mdc-form-field-prefix .mat-mdc-icon-button .mat-mdc-icon, .mat-mdc-form-field-suffix .mat-mdc-icon-button .mat-mdc-icon {
  height: 1.125em;
  line-height: 1.125
}

.mat-mdc-form-field-infix {
  padding: .5em 0;
  border-top: .84375em solid transparent
}

.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label, .mat-mdc-form-field-can-float .mat-mdc-input-server:focus + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%
}

.mat-mdc-form-field-can-float .mat-mdc-input-server[label]:not(:label-shown) + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%
}

.mat-mdc-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: .84375em
}

.mat-mdc-form-field-label {
  top: 1.34375em
}

.mat-mdc-form-field-underline {
  bottom: 1.34375em
}

.mat-mdc-form-field-subscript-wrapper {
  font-size: 75%;
  top: calc(100% - 1.7916666667em)
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 1.25em
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-infix {
  padding: .4375em 0
}

.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label, .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float .mat-mdc-input-server:focus + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%
}

.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float .mat-mdc-form-field-autofill-control:-webkit-autofill + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%
}

.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float .mat-mdc-input-server[label]:not(:label-shown) + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
  top: 1.28125em
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  bottom: 1.25em
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-subscript-wrapper {
  margin-top: .5416666667em;
  top: calc(100% - 1.6666666667em)
}

@media print {
  .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label, .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float .mat-mdc-input-server:focus + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
    transform: translateY(-1.28122em) scale(0.75)
  }

  .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float .mat-mdc-form-field-autofill-control:-webkit-autofill + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
    transform: translateY(-1.28121em) scale(0.75)
  }

  .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float .mat-mdc-input-server[label]:not(:label-shown) + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
    transform: translateY(-1.2812em) scale(0.75)
  }
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix {
  padding: .25em 0 .75em 0
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em
}

.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label, .mat-mdc-form-field-appearance-fill.mat-mdc-form-field-can-float .mat-mdc-input-server:focus + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%
}

.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-can-float .mat-mdc-input-server[label]:not(:label-shown) + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label, .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float .mat-mdc-input-server:focus + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float .mat-mdc-input-server[label]:not(:label-shown) + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%
}

.mat-mdc-grid-tile-header, .mat-mdc-grid-tile-footer {
}

.mat-mdc-grid-tile-header .mat-mdc-line, .mat-mdc-grid-tile-footer .mat-mdc-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-mdc-grid-tile-header .mat-mdc-line:nth-child(n+2), .mat-mdc-grid-tile-footer .mat-mdc-line:nth-child(n+2) {
  font-size: 12px
}

input.mat-mdc-input-element {
  margin-top: -0.0625em
}

.mat-mdc-menu-item {;

  font-weight: 400
}

.mat-mdc-paginator, .mat-mdc-paginator-page-size .mat-mdc-select-trigger {;
  font-size: 12px
}

.mat-mdc-radio-button {
}

.mat-mdc-select {
}

.mat-mdc-select-trigger {
  height: 1.125em
}

.mat-mdc-slide-toggle-content {
}

.mat-mdc-slider-thumb-label-text {;
  font-size: 12px;
  font-weight: 500
}

.mat-mdc-stepper-vertical, .mat-mdc-stepper-horizontal {
}

.mat-mdc-step-label {

  font-weight: 400
}

.mat-mdc-step-sub-label-error {
  font-weight: normal
}

.mat-mdc-step-label-error {
}

.mat-mdc-step-label-selected {

  font-weight: 500
}

.mat-mdc-tab-group {
}

.mat-mdc-tab-label, .mat-mdc-tab-link {;

  font-weight: 500
}

.mat-mdc-toolbar, .mat-mdc-toolbar h1, .mat-mdc-toolbar h2, .mat-mdc-toolbar h3, .mat-mdc-toolbar h4, .mat-mdc-toolbar h5, .mat-mdc-toolbar h6 {

  letter-spacing: normal;
  margin: 0
}

.mat-mdc-tooltip {;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px
}

.mat-mdc-tooltip-handset {

  padding-top: 8px;
  padding-bottom: 8px
}

.mat-mdc-list-item {
}

.mat-mdc-list-option {
}

.mat-mdc-list-base .mat-mdc-list-item {
  font-size: 1.1rem
}

.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-line:nth-child(n+2) {
}

.mat-mdc-list-base .mat-mdc-list-option {
  font-size: 1.1rem
}

.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-line:nth-child(n+2) {
}

.mat-mdc-list-base .mat-mdc-subheader {;

  font-weight: 500
}

.mat-mdc-list-base[dense] .mat-mdc-list-item {
  font-size: 12px
}

.mat-mdc-list-base[dense] .mat-mdc-list-item .mat-mdc-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-mdc-list-base[dense] .mat-mdc-list-item .mat-mdc-line:nth-child(n+2) {
  font-size: 12px
}

.mat-mdc-list-base[dense] .mat-mdc-list-option {
  font-size: 12px
}

.mat-mdc-list-base[dense] .mat-mdc-list-option .mat-mdc-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box
}

.mat-mdc-list-base[dense] .mat-mdc-list-option .mat-mdc-line:nth-child(n+2) {
  font-size: 12px
}

.mat-mdc-list-base[dense] .mat-mdc-subheader {;
  font-size: 12px;
  font-weight: 500
}

.mat-mdc-option {;
  font-size: 1.1rem
}

.mat-mdc-optgroup-label {

  letter-spacing: normal
}

.mat-mdc-simple-snackbar {;
}

.mat-mdc-simple-snackbar-action {
  line-height: 1;
  font-size: inherit;
  font-weight: 500
}

.mat-mdc-tree {
}

.mat-mdc-tree-node, .mat-mdc-nested-tree-node {
  font-weight: 400;
}

.mat-mdc-ripple {
  overflow: hidden;
  position: relative
}

.mat-mdc-ripple:not(:empty) {
  transform: translateZ(0)
}

.mat-mdc-ripple.mat-mdc-ripple-unbounded {
  overflow: visible
}

.mat-mdc-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0)
}

.cdk-high-contrast-active .mat-mdc-ripple-element {
  display: none
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1502;
}

.cdk-overlay-container:empty {
  display: none
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll
}

@keyframes cdk-text-field-autofill-start { /*!*/
}

@keyframes cdk-text-field-autofill-end { /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms
}

textarea.cdk-textarea-autosize {
  resize: none
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important
}

.mat-mdc-focus-indicator {
  position: relative
}

.mat-mdc-ripple-element {
  background-color: rgba(0, 0, 0, .1)
}

.mat-mdc-option {
  color: @text
}

.mat-mdc-option:hover:not(.mat-mdc-option-disabled), .mat-mdc-option:focus:not(.mat-mdc-option-disabled) {
  background: rgba(0, 0, 0, .04)
}

.mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple):not(.mat-mdc-option-disabled) {
  background: rgba(0, 0, 0, .04)
}

.mat-mdc-option.mat-mdc-active {
  background: rgba(0, 0, 0, .04);
  color: @text
}

.mat-mdc-option.mat-mdc-option-disabled {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
  color: @primary
}

.mat-mdc-accent .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
  color: #ff4081
}

.mat-mdc-warn .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
  color: #f44336
}

.mat-mdc-optgroup-label {
  color: @text
}

.mat-mdc-optgroup-disabled .mat-mdc-optgroup-label {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-pseudo-checkbox {
  color: @text
}

.mat-mdc-pseudo-checkbox::after {
  color: #fafafa
}

.mat-mdc-pseudo-checkbox-disabled {
  color: #b0b0b0
}

.mat-mdc-primary .mat-mdc-pseudo-checkbox-checked, .mat-mdc-primary .mat-mdc-pseudo-checkbox-indeterminate {
  background: @primary
}

.mat-mdc-pseudo-checkbox-checked, .mat-mdc-pseudo-checkbox-indeterminate, .mat-mdc-accent .mat-mdc-pseudo-checkbox-checked, .mat-mdc-accent .mat-mdc-pseudo-checkbox-indeterminate {
  background: #ff4081
}

.mat-mdc-warn .mat-mdc-pseudo-checkbox-checked, .mat-mdc-warn .mat-mdc-pseudo-checkbox-indeterminate {
  background: #f44336
}

.mat-mdc-pseudo-checkbox-checked.mat-mdc-pseudo-checkbox-disabled, .mat-mdc-pseudo-checkbox-indeterminate.mat-mdc-pseudo-checkbox-disabled {
  background: #b0b0b0
}

.mat-mdc-app-background {
  background-color: #fafafa;
  color: @text
}

.mat-mdc-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)
}

.mat-mdc-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}

.mat-mdc-theme-loaded-marker {
  display: none
}

.mat-mdc-autocomplete-panel {
  min-width: 60px;
  background: #fff;
  color: @text
}

.mat-mdc-autocomplete-panel:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-active):not(:hover) {
  background: #fff
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-active):not(:hover):not(.mat-mdc-option-disabled) {
  color: @text
}

.mat-mdc-badge-content {
  color: #fff;
  background: @primary
}

.cdk-high-contrast-active .mat-mdc-badge-content {
  outline: solid 1px;
  border-radius: 0
}

.mat-mdc-badge-accent .mat-mdc-badge-content {
  background: #ff4081;
  color: #fff
}

.mat-mdc-badge-warn .mat-mdc-badge-content {
  color: #fff;
  background: #f44336
}

.mat-mdc-badge {
  position: relative
}

.mat-mdc-badge-hidden .mat-mdc-badge-content {
  display: none
}

.mat-mdc-badge-disabled .mat-mdc-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none
}

.ng-animate-disabled .mat-mdc-badge-content, .mat-mdc-badge-content._mat-mdc-animation-noopable {
  transition: none
}

.mat-mdc-badge-content.mat-mdc-badge-active {
  transform: none
}

.mat-mdc-badge-small .mat-mdc-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px
}

.mat-mdc-badge-small.mat-mdc-badge-above .mat-mdc-badge-content {
  top: -8px
}

.mat-mdc-badge-small.mat-mdc-badge-below .mat-mdc-badge-content {
  bottom: -8px
}

.mat-mdc-badge-small.mat-mdc-badge-before .mat-mdc-badge-content {
  left: -16px
}

[dir=rtl] .mat-mdc-badge-small.mat-mdc-badge-before .mat-mdc-badge-content {
  left: auto;
  right: -16px
}

.mat-mdc-badge-small.mat-mdc-badge-after .mat-mdc-badge-content {
  right: -16px
}

[dir=rtl] .mat-mdc-badge-small.mat-mdc-badge-after .mat-mdc-badge-content {
  right: auto;
  left: -16px
}

.mat-mdc-badge-small.mat-mdc-badge-overlap.mat-mdc-badge-before .mat-mdc-badge-content {
  left: -8px
}

[dir=rtl] .mat-mdc-badge-small.mat-mdc-badge-overlap.mat-mdc-badge-before .mat-mdc-badge-content {
  left: auto;
  right: -8px
}

.mat-mdc-badge-small.mat-mdc-badge-overlap.mat-mdc-badge-after .mat-mdc-badge-content {
  right: -8px
}

[dir=rtl] .mat-mdc-badge-small.mat-mdc-badge-overlap.mat-mdc-badge-after .mat-mdc-badge-content {
  right: auto;
  left: -8px
}

.mat-mdc-badge-medium .mat-mdc-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px
}

.mat-mdc-badge-medium.mat-mdc-badge-above .mat-mdc-badge-content {
  top: -11px
}

.mat-mdc-badge-medium.mat-mdc-badge-below .mat-mdc-badge-content {
  bottom: -11px
}

.mat-mdc-badge-medium.mat-mdc-badge-before .mat-mdc-badge-content {
  left: -22px
}

[dir=rtl] .mat-mdc-badge-medium.mat-mdc-badge-before .mat-mdc-badge-content {
  left: auto;
  right: -22px
}

.mat-mdc-badge-medium.mat-mdc-badge-after .mat-mdc-badge-content {
  right: -22px
}

[dir=rtl] .mat-mdc-badge-medium.mat-mdc-badge-after .mat-mdc-badge-content {
  right: auto;
  left: -22px
}

.mat-mdc-badge-medium.mat-mdc-badge-overlap.mat-mdc-badge-before .mat-mdc-badge-content {
  left: -11px
}

[dir=rtl] .mat-mdc-badge-medium.mat-mdc-badge-overlap.mat-mdc-badge-before .mat-mdc-badge-content {
  left: auto;
  right: -11px
}

.mat-mdc-badge-medium.mat-mdc-badge-overlap.mat-mdc-badge-after .mat-mdc-badge-content {
  right: -11px
}

[dir=rtl] .mat-mdc-badge-medium.mat-mdc-badge-overlap.mat-mdc-badge-after .mat-mdc-badge-content {
  right: auto;
  left: -11px
}

.mat-mdc-badge-large .mat-mdc-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px
}

.mat-mdc-badge-large.mat-mdc-badge-above .mat-mdc-badge-content {
  top: -14px
}

.mat-mdc-badge-large.mat-mdc-badge-below .mat-mdc-badge-content {
  bottom: -14px
}

.mat-mdc-badge-large.mat-mdc-badge-before .mat-mdc-badge-content {
  left: -28px
}

[dir=rtl] .mat-mdc-badge-large.mat-mdc-badge-before .mat-mdc-badge-content {
  left: auto;
  right: -28px
}

.mat-mdc-badge-large.mat-mdc-badge-after .mat-mdc-badge-content {
  right: -28px
}

[dir=rtl] .mat-mdc-badge-large.mat-mdc-badge-after .mat-mdc-badge-content {
  right: auto;
  left: -28px
}

.mat-mdc-badge-large.mat-mdc-badge-overlap.mat-mdc-badge-before .mat-mdc-badge-content {
  left: -14px
}

[dir=rtl] .mat-mdc-badge-large.mat-mdc-badge-overlap.mat-mdc-badge-before .mat-mdc-badge-content {
  left: auto;
  right: -14px
}

.mat-mdc-badge-large.mat-mdc-badge-overlap.mat-mdc-badge-after .mat-mdc-badge-content {
  right: -14px
}

[dir=rtl] .mat-mdc-badge-large.mat-mdc-badge-overlap.mat-mdc-badge-after .mat-mdc-badge-content {
  right: auto;
  left: -14px
}

.mat-mdc-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: @text
}

.mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-stroked-button {
  color: inherit;
  background: transparent
}

.mat-mdc-button.mat-mdc-primary, .mat-mdc-icon-button.mat-mdc-primary, .mat-mdc-stroked-button.mat-mdc-primary {
  color: @primary
}

.mat-mdc-button.mat-mdc-accent, .mat-mdc-icon-button.mat-mdc-accent, .mat-mdc-stroked-button.mat-mdc-accent {
  color: #ff4081
}

.mat-mdc-button.mat-mdc-warn, .mat-mdc-icon-button.mat-mdc-warn, .mat-mdc-stroked-button.mat-mdc-warn {
  color: #f44336
}

.mat-mdc-button.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-button.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-button.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-button.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-icon-button.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-icon-button.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-icon-button.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-icon-button.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-stroked-button.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-stroked-button.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-stroked-button.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-stroked-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
  color: rgba(0, 0, 0, .26)
}

.mat-mdc-button.mat-mdc-primary .mat-mdc-button-focus-overlay, .mat-mdc-icon-button.mat-mdc-primary .mat-mdc-button-focus-overlay, .mat-mdc-stroked-button.mat-mdc-primary .mat-mdc-button-focus-overlay {
  background-color: @primary
}

.mat-mdc-button.mat-mdc-accent .mat-mdc-button-focus-overlay, .mat-mdc-icon-button.mat-mdc-accent .mat-mdc-button-focus-overlay, .mat-mdc-stroked-button.mat-mdc-accent .mat-mdc-button-focus-overlay {
  background-color: #ff4081
}

.mat-mdc-button.mat-mdc-warn .mat-mdc-button-focus-overlay, .mat-mdc-icon-button.mat-mdc-warn .mat-mdc-button-focus-overlay, .mat-mdc-stroked-button.mat-mdc-warn .mat-mdc-button-focus-overlay {
  background-color: #f44336
}

.mat-mdc-button.mat-mdc-button-disabled .mat-mdc-button-focus-overlay, .mat-mdc-icon-button.mat-mdc-button-disabled .mat-mdc-button-focus-overlay, .mat-mdc-stroked-button.mat-mdc-button-disabled .mat-mdc-button-focus-overlay {
  background-color: transparent
}

.mat-mdc-button .mat-mdc-ripple-element, .mat-mdc-icon-button .mat-mdc-ripple-element, .mat-mdc-stroked-button .mat-mdc-ripple-element {
  opacity: .1;
  background-color: currentColor
}

.mat-mdc-button-focus-overlay {
  background: #000
}

.mat-mdc-stroked-button:not(.mat-mdc-button-disabled) {
  border-color: rgba(0, 0, 0, .12)
}

.mat-mdc-flat-button, .mat-mdc-raised-button, .mat-mdc-fab, .mat-mdc-mini-fab {
  color: @text;
  background-color: #fff
}

.mat-mdc-flat-button.mat-mdc-primary, .mat-mdc-raised-button.mat-mdc-primary, .mat-mdc-fab.mat-mdc-primary, .mat-mdc-mini-fab.mat-mdc-primary {
  color: #fff
}

.mat-mdc-flat-button.mat-mdc-accent, .mat-mdc-raised-button.mat-mdc-accent, .mat-mdc-fab.mat-mdc-accent, .mat-mdc-mini-fab.mat-mdc-accent {
  color: #fff
}

.mat-mdc-flat-button.mat-mdc-warn, .mat-mdc-raised-button.mat-mdc-warn, .mat-mdc-fab.mat-mdc-warn, .mat-mdc-mini-fab.mat-mdc-warn {
  color: #fff
}

.mat-mdc-flat-button.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-flat-button.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-flat-button.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-flat-button.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-button-disabled.mat-mdc-button-disabled {
  color: rgba(0, 0, 0, .26)
}

.mat-mdc-flat-button.mat-mdc-primary, .mat-mdc-raised-button.mat-mdc-primary, .mat-mdc-fab.mat-mdc-primary, .mat-mdc-mini-fab.mat-mdc-primary {
  background-color: @primary
}

.mat-mdc-flat-button.mat-mdc-accent, .mat-mdc-raised-button.mat-mdc-accent, .mat-mdc-fab.mat-mdc-accent, .mat-mdc-mini-fab.mat-mdc-accent {
  background-color: #ff4081
}

.mat-mdc-flat-button.mat-mdc-warn, .mat-mdc-raised-button.mat-mdc-warn, .mat-mdc-fab.mat-mdc-warn, .mat-mdc-mini-fab.mat-mdc-warn {
  background-color: #f44336
}

.mat-mdc-flat-button.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-flat-button.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-flat-button.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-flat-button.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-raised-button.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-fab.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-primary.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-accent.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-warn.mat-mdc-button-disabled, .mat-mdc-mini-fab.mat-mdc-button-disabled.mat-mdc-button-disabled {
  background-color: rgba(0, 0, 0, .12)
}

.mat-mdc-flat-button.mat-mdc-primary .mat-mdc-ripple-element, .mat-mdc-raised-button.mat-mdc-primary .mat-mdc-ripple-element, .mat-mdc-fab.mat-mdc-primary .mat-mdc-ripple-element, .mat-mdc-mini-fab.mat-mdc-primary .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .1)
}

.mat-mdc-flat-button.mat-mdc-accent .mat-mdc-ripple-element, .mat-mdc-raised-button.mat-mdc-accent .mat-mdc-ripple-element, .mat-mdc-fab.mat-mdc-accent .mat-mdc-ripple-element, .mat-mdc-mini-fab.mat-mdc-accent .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .1)
}

.mat-mdc-flat-button.mat-mdc-warn .mat-mdc-ripple-element, .mat-mdc-raised-button.mat-mdc-warn .mat-mdc-ripple-element, .mat-mdc-fab.mat-mdc-warn .mat-mdc-ripple-element, .mat-mdc-mini-fab.mat-mdc-warn .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .1)
}

.mat-mdc-stroked-button:not([class*=mat-mdc-elevation-z]), .mat-mdc-flat-button:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-raised-button:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-raised-button:not(.mat-mdc-button-disabled):active:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.mat-mdc-raised-button.mat-mdc-button-disabled:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-fab:not([class*=mat-mdc-elevation-z]), .mat-mdc-mini-fab:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-fab:not(.mat-mdc-button-disabled):active:not([class*=mat-mdc-elevation-z]), .mat-mdc-mini-fab:not(.mat-mdc-button-disabled):active:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.mat-mdc-fab.mat-mdc-button-disabled:not([class*=mat-mdc-elevation-z]), .mat-mdc-mini-fab.mat-mdc-button-disabled:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-button-toggle-standalone, .mat-mdc-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-button-toggle-standalone.mat-mdc-button-toggle-appearance-standard, .mat-mdc-button-toggle-group-appearance-standard {
  box-shadow: none
}

.mat-mdc-button-toggle {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-button-toggle .mat-mdc-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, .12)
}

.mat-mdc-button-toggle-appearance-standard {
  color: @text;
  background: #fff
}

.mat-mdc-button-toggle-appearance-standard .mat-mdc-button-toggle-focus-overlay {
  background-color: #000
}

.mat-mdc-button-toggle-group-appearance-standard .mat-mdc-button-toggle + .mat-mdc-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, .12)
}

[dir=rtl] .mat-mdc-button-toggle-group-appearance-standard .mat-mdc-button-toggle + .mat-mdc-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, .12)
}

.mat-mdc-button-toggle-group-appearance-standard.mat-mdc-button-toggle-vertical .mat-mdc-button-toggle + .mat-mdc-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, .12)
}

.mat-mdc-button-toggle-checked {
  background-color: #e0e0e0;
  color: @text
}

.mat-mdc-button-toggle-checked.mat-mdc-button-toggle-appearance-standard {
  color: @text
}

.mat-mdc-button-toggle-disabled {
  color: rgba(0, 0, 0, .26);
  background-color: #eee
}

.mat-mdc-button-toggle-disabled.mat-mdc-button-toggle-appearance-standard {
  background: #fff
}

.mat-mdc-button-toggle-disabled.mat-mdc-button-toggle-checked {
  background-color: #bdbdbd
}

.mat-mdc-button-toggle-standalone.mat-mdc-button-toggle-appearance-standard, .mat-mdc-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, .12)
}

.mat-mdc-button-toggle-appearance-standard .mat-mdc-button-toggle-label-content {
  line-height: 48px
}

.mat-mdc-card {
  background: #fff;
  color: @text
}

.mat-mdc-card:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-card.mat-mdc-card-flat:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-card-subtitle {
  color: @textSecondary
}

.mat-mdc-checkbox-frame {
  border-color: @text
}

.mat-mdc-checkbox-checkmark {
  fill: #fafafa
}

.mat-mdc-checkbox-checkmark-path {
  stroke: #fafafa !important
}

.mat-mdc-checkbox-mixedmark {
  background-color: #fafafa
}

.mat-mdc-checkbox-indeterminate.mat-mdc-primary .mat-mdc-checkbox-background, .mat-mdc-checkbox-checked.mat-mdc-primary .mat-mdc-checkbox-background {
  background-color: @primary
}

.mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background, .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
  background-color: #ff4081
}

.mat-mdc-checkbox-indeterminate.mat-mdc-warn .mat-mdc-checkbox-background, .mat-mdc-checkbox-checked.mat-mdc-warn .mat-mdc-checkbox-background {
  background-color: #f44336
}

.mat-mdc-checkbox-disabled.mat-mdc-checkbox-checked .mat-mdc-checkbox-background, .mat-mdc-checkbox-disabled.mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background {
  background-color: #b0b0b0
}

.mat-mdc-checkbox-disabled:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: #b0b0b0
}

.mat-mdc-checkbox-disabled .mat-mdc-checkbox-label {
  color: @disabledDark
}

.mat-mdc-checkbox .mat-mdc-ripple-element {
  background-color: #000
}

.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-mdc-primary .mat-mdc-ripple-element, .mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-mdc-primary .mat-mdc-ripple-element {
  background: @primary
}

.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-mdc-accent .mat-mdc-ripple-element, .mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-mdc-accent .mat-mdc-ripple-element {
  background: #ff4081
}

.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-mdc-warn .mat-mdc-ripple-element, .mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-mdc-warn .mat-mdc-ripple-element {
  background: #f44336
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #e0e0e0;
  color: @text
}

.mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
  color: @text;
  opacity: .4
}

.mat-mdc-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-disabled) .mat-mdc-chip-remove:hover {
  opacity: .54
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-disabled {
  opacity: .4
}

.mat-mdc-chip.mat-mdc-standard-chip::after {
  background: #000
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-primary {
  background-color: @primary;
  color: #fff
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-primary .mat-mdc-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-primary .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .1)
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-warn {
  background-color: #f44336;
  color: #fff
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-warn .mat-mdc-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-warn .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .1)
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-accent {
  background-color: #ff4081;
  color: #fff
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-accent .mat-mdc-chip-remove {
  color: #fff;
  opacity: .4
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-mdc-accent .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .1)
}

.mat-mdc-table {
  background: #fff
}

.mat-mdc-table thead, .mat-mdc-table tbody, .mat-mdc-table tfoot, mat-mdc-header-row, mat-mdc-row, mat-mdc-footer-row, [mat-mdc-header-row], [mat-mdc-row], [mat-mdc-footer-row], .mat-mdc-table-sticky {
  background: inherit
}

mat-mdc-row, mat-mdc-header-row, mat-mdc-footer-row, th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
  border-bottom-color: rgba(0, 0, 0, .12)
}

.mat-mdc-header-cell {
  color: @text
}

.mat-mdc-cell, .mat-mdc-footer-cell {
  color: @text
}

.mat-mdc-calendar-arrow {
  border-top-color: @text
}

.mat-datepicker-toggle, .mat-datepicker-content .mat-mdc-calendar-next-button, .mat-datepicker-content .mat-mdc-calendar-previous-button {
  color: @text
}

.mat-mdc-calendar-table-header {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, .12)
}

.mat-mdc-calendar-body-label {
  color: @text
}

.mat-mdc-calendar-body-cell-content, .mat-date-range-input-separator {
  color: @text;
  border-color: transparent
}

.mat-mdc-calendar-body-disabled > .mat-mdc-calendar-body-cell-content:not(.mat-mdc-calendar-body-selected):not(.mat-mdc-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, .38)
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-calendar-body-cell:not(.mat-mdc-calendar-body-disabled):hover > .mat-mdc-calendar-body-cell-content:not(.mat-mdc-calendar-body-selected):not(.mat-mdc-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-mdc-calendar-body-active > .mat-mdc-calendar-body-cell-content:not(.mat-mdc-calendar-body-selected):not(.mat-mdc-calendar-body-comparison-identical), .cdk-program-focused .mat-mdc-calendar-body-active > .mat-mdc-calendar-body-cell-content:not(.mat-mdc-calendar-body-selected):not(.mat-mdc-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, .04)
}

.mat-mdc-calendar-body-in-preview {
  color: rgba(0, 0, 0, .24)
}

.mat-mdc-calendar-body-today:not(.mat-mdc-calendar-body-selected):not(.mat-mdc-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, .38)
}

.mat-mdc-calendar-body-disabled > .mat-mdc-calendar-body-today:not(.mat-mdc-calendar-body-selected):not(.mat-mdc-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, .18)
}

.mat-mdc-calendar-body-in-range::before {
  background: rgba(63, 81, 181, .2)
}

.mat-mdc-calendar-body-comparison-identical, .mat-mdc-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, .2)
}

.mat-mdc-calendar-body-comparison-bridge-start::before, [dir=rtl] .mat-mdc-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-mdc-calendar-body-comparison-bridge-end::before, [dir=rtl] .mat-mdc-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-mdc-calendar-body-in-range > .mat-mdc-calendar-body-comparison-identical, .mat-mdc-calendar-body-in-comparison-range.mat-mdc-calendar-body-in-range::after {
  background: #a8dab5
}

.mat-mdc-calendar-body-comparison-identical.mat-mdc-calendar-body-selected, .mat-mdc-calendar-body-in-comparison-range > .mat-mdc-calendar-body-selected {
  background: #46a35e
}

.mat-mdc-calendar-body-selected {
  background-color: @primary;
  color: #fff
}

.mat-mdc-calendar-body-disabled > .mat-mdc-calendar-body-selected {
  background-color: rgba(63, 81, 181, .4)
}

.mat-mdc-calendar-body-today.mat-mdc-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: @text
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-in-range::before {
  background: rgba(255, 64, 129, .2)
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-comparison-identical, .mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, .2)
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-comparison-bridge-start::before, .mat-datepicker-content.mat-mdc-accent [dir=rtl] .mat-mdc-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-comparison-bridge-end::before, .mat-datepicker-content.mat-mdc-accent [dir=rtl] .mat-mdc-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-in-range > .mat-mdc-calendar-body-comparison-identical, .mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-in-comparison-range.mat-mdc-calendar-body-in-range::after {
  background: #a8dab5
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-comparison-identical.mat-mdc-calendar-body-selected, .mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-in-comparison-range > .mat-mdc-calendar-body-selected {
  background: #46a35e
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-selected {
  background-color: #ff4081;
  color: #fff
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-disabled > .mat-mdc-calendar-body-selected {
  background-color: rgba(255, 64, 129, .4)
}

.mat-datepicker-content.mat-mdc-accent .mat-mdc-calendar-body-today.mat-mdc-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-in-range::before {
  background: rgba(244, 67, 54, .2)
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-comparison-identical, .mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, .2)
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-comparison-bridge-start::before, .mat-datepicker-content.mat-mdc-warn [dir=rtl] .mat-mdc-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-comparison-bridge-end::before, .mat-datepicker-content.mat-mdc-warn [dir=rtl] .mat-mdc-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-in-range > .mat-mdc-calendar-body-comparison-identical, .mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-in-comparison-range.mat-mdc-calendar-body-in-range::after {
  background: #a8dab5
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-comparison-identical.mat-mdc-calendar-body-selected, .mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-in-comparison-range > .mat-mdc-calendar-body-selected {
  background: #46a35e
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-selected {
  background-color: #f44336;
  color: #fff
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-disabled > .mat-mdc-calendar-body-selected {
  background-color: rgba(244, 67, 54, .4)
}

.mat-datepicker-content.mat-mdc-warn .mat-mdc-calendar-body-today.mat-mdc-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-datepicker-toggle-active {
  color: @primary
}

.mat-datepicker-toggle-active.mat-mdc-accent {
  color: #ff4081
}

.mat-datepicker-toggle-active.mat-mdc-warn {
  color: #f44336
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: @text
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, .12)
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, .12)
}

.mat-mdc-expansion-panel {
  background: #fff;
  color: @text
}

.mat-mdc-expansion-panel:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-action-row {
  border-top-color: rgba(0, 0, 0, .12)
}

.mat-mdc-expansion-panel .mat-mdc-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-mdc-expansion-panel .mat-mdc-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-mdc-expansion-panel:not(.mat-mdc-expanded) .mat-mdc-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, .04)
}

@media (hover: none) {
  .mat-mdc-expansion-panel:not(.mat-mdc-expanded):not([aria-disabled=true]) .mat-mdc-expansion-panel-header:hover {
    background: #fff
  }
}

.mat-mdc-expansion-panel-header-title {
  color: @text
}

.mat-mdc-expansion-panel-header-description, .mat-mdc-expansion-indicator::after {
  color: @text
}

.mat-mdc-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, .26)
}

.mat-mdc-expansion-panel-header[aria-disabled=true] .mat-mdc-expansion-panel-header-title, .mat-mdc-expansion-panel-header[aria-disabled=true] .mat-mdc-expansion-panel-header-description {
  color: inherit
}

.mat-mdc-expansion-panel-header {
  height: 48px
}

.mat-mdc-expansion-panel-header.mat-mdc-expanded {
  height: 30px
}

.mat-mdc-form-field-label {
  color: rgba(0, 0, 0, .6)
}

.mat-mdc-hint {
  color: rgba(0, 0, 0, .6)
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label {
  color: @primary
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label.mat-mdc-accent {
  color: #ff4081
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label.mat-mdc-warn {
  color: #f44336
}

.mat-mdc-focused .mat-mdc-form-field-required-marker {
  color: #ff4081
}

.mat-mdc-form-field-ripple {
  background-color: @text
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple {
  background-color: @primary
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple.mat-mdc-accent {
  background-color: #ff4081
}

.mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-ripple.mat-mdc-warn {
  background-color: #f44336
}

.mat-mdc-form-field-type-mat-mdc-native-select.mat-mdc-focused:not(.mat-mdc-form-field-invalid) .mat-mdc-form-field-infix::after {
  color: @primary
}

.mat-mdc-form-field-type-mat-mdc-native-select.mat-mdc-focused:not(.mat-mdc-form-field-invalid).mat-mdc-accent .mat-mdc-form-field-infix::after {
  color: #ff4081
}

.mat-mdc-form-field-type-mat-mdc-native-select.mat-mdc-focused:not(.mat-mdc-form-field-invalid).mat-mdc-warn .mat-mdc-form-field-infix::after {
  color: #f44336
}

.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
  color: #f44336
}

.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label.mat-mdc-accent, .mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label .mat-mdc-form-field-required-marker {
  color: #f44336
}

.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-ripple, .mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-ripple.mat-mdc-accent {
  background-color: #f44336
}

.mat-mdc-error {
  color: #f44336
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
  color: @lightGray
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-hint {
  color: @text
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  background-color: rgba(0, 0, 0, .42)
}

.mat-mdc-form-field-appearance-legacy.mat-form-field-disabled .mat-mdc-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x
}

.mat-mdc-form-field-appearance-standard .mat-mdc-form-field-underline {
  background-color: rgba(0, 0, 0, .42)
}

.mat-mdc-form-field-appearance-standard.mat-form-field-disabled .mat-mdc-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: rgba(0, 0, 0, .04)
}

.mat-mdc-form-field-appearance-fill.mat-form-field-disabled .mat-mdc-form-field-flex {
  background-color: rgba(0, 0, 0, .02)
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-underline::before {
  background-color: rgba(0, 0, 0, .42)
}

.mat-mdc-form-field-appearance-fill.mat-form-field-disabled .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-form-field-appearance-fill.mat-form-field-disabled .mat-mdc-form-field-underline::before {
  background-color: transparent
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: rgba(0, 0, 0, .12)
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
  color: @text
}

.mat-mdc-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline-thick {
  color: @primary
}

.mat-mdc-form-field-appearance-outline.mat-mdc-focused.mat-mdc-accent .mat-mdc-form-field-outline-thick {
  color: #ff4081
}

.mat-mdc-form-field-appearance-outline.mat-mdc-focused.mat-mdc-warn .mat-mdc-form-field-outline-thick {
  color: #f44336
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-invalid.mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick {
  color: #f44336
}

.mat-mdc-form-field-appearance-outline.mat-form-field-disabled .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-form-field-appearance-outline.mat-form-field-disabled .mat-mdc-form-field-outline {
  color: rgba(0, 0, 0, .06)
}

.mat-mdc-icon.mat-mdc-primary {
  color: @primary
}

.mat-mdc-icon.mat-mdc-accent {
  color: #ff4081
}

.mat-mdc-icon.mat-mdc-warn {
  color: #f44336
}

.mat-mdc-form-field-type-mat-mdc-native-select .mat-mdc-form-field-infix::after {
  color: @text
}

.mat-mdc-input-element:disabled, .mat-mdc-form-field-type-mat-mdc-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-input-element {
  caret-color: @primary
}

.mat-mdc-input-element::placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-mdc-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-mdc-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-mdc-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-mdc-form-field.mat-mdc-accent .mat-mdc-input-element {
  caret-color: #ff4081
}

.mat-mdc-form-field.mat-mdc-warn .mat-mdc-input-element, .mat-mdc-form-field-invalid .mat-mdc-input-element {
  caret-color: #f44336
}

.mat-mdc-form-field-type-mat-mdc-native-select.mat-mdc-form-field-invalid .mat-mdc-form-field-infix::after {
  color: #f44336
}

.mat-mdc-list-base .mat-mdc-list-item {
  color: @text
}

.mat-mdc-list-base .mat-mdc-list-option {
  color: @text
}

.mat-mdc-list-base .mat-mdc-subheader {
  color: @text
}

.mat-mdc-list-item-disabled {
  background-color: #eee
}

.mat-mdc-list-option:hover, .mat-mdc-list-option:focus, .mat-mdc-nav-list .mat-mdc-list-item:hover, .mat-mdc-nav-list .mat-mdc-list-item:focus, .mat-mdc-action-list .mat-mdc-list-item:hover, .mat-mdc-action-list .mat-mdc-list-item:focus {
  background: rgba(0, 0, 0, .04)
}

.mat-mdc-list-single-selected-option, .mat-mdc-list-single-selected-option:hover, .mat-mdc-list-single-selected-option:focus {
  background: rgba(0, 0, 0, .12)
}


.mat-mdc-menu-panel {
  background: #fff
}

.mat-mdc-menu-panel:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-menu-item {
  background: transparent;
  color: @text
}

.mat-mdc-menu-item[disabled], .mat-mdc-menu-item[disabled]::after {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-menu-item .mat-mdc-icon-no-color, .mat-mdc-menu-item-submenu-trigger::after {
  color: @text
}

.mat-mdc-menu-item:hover:not([disabled]), .mat-mdc-menu-item.cdk-program-focused:not([disabled]), .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]), .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, .04)
}

.mat-mdc-paginator {
  background: #fff
}

.mat-mdc-paginator, .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
  color: @text
}

.mat-mdc-paginator-decrement, .mat-mdc-paginator-increment {
  border-top: 2px solid @text;
  border-right: 2px solid @text
}

.mat-mdc-paginator-first, .mat-mdc-paginator-last {
  border-top: 2px solid @text
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement, .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment, .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first, .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, .38)
}

.mat-mdc-paginator-container {
  min-height: 56px
}

.mat-mdc-progress-bar-background {
  fill: #c5cae9
}

.mat-mdc-progress-bar-buffer {
  background-color: #c5cae9
}

.mat-mdc-progress-bar-fill::after {
  background-color: @primary
}

.mat-mdc-progress-bar.mat-mdc-accent .mat-mdc-progress-bar-background {
  fill: #ff80ab
}

.mat-mdc-progress-bar.mat-mdc-accent .mat-mdc-progress-bar-buffer {
  background-color: #ff80ab
}

.mat-mdc-progress-bar.mat-mdc-accent .mat-mdc-progress-bar-fill::after {
  background-color: #ff4081
}

.mat-mdc-progress-bar.mat-mdc-warn .mat-mdc-progress-bar-background {
  fill: #ffcdd2
}

.mat-mdc-progress-bar.mat-mdc-warn .mat-mdc-progress-bar-buffer {
  background-color: #ffcdd2
}

.mat-mdc-progress-bar.mat-mdc-warn .mat-mdc-progress-bar-fill::after {
  background-color: #f44336
}

.mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
  stroke: @primary
}

.mat-mdc-progress-spinner.mat-mdc-accent circle, .mat-mdc-spinner.mat-mdc-accent circle {
  stroke: #ff4081
}

.mat-mdc-progress-spinner.mat-mdc-warn circle, .mat-mdc-spinner.mat-mdc-warn circle {
  stroke: #f44336
}

.mat-mdc-radio-outer-circle {
  border-color: @text
}

.mat-mdc-radio-button.mat-mdc-primary.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: @primary
}

.mat-mdc-radio-button.mat-mdc-primary .mat-mdc-radio-inner-circle, .mat-mdc-radio-button.mat-mdc-primary .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple), .mat-mdc-radio-button.mat-mdc-primary.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple, .mat-mdc-radio-button.mat-mdc-primary:active .mat-mdc-radio-persistent-ripple {
  background-color: @primary
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: #ff4081
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle, .mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple), .mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple, .mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple {
  background-color: #ff4081
}

.mat-mdc-radio-button.mat-mdc-warn.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: #f44336
}

.mat-mdc-radio-button.mat-mdc-warn .mat-mdc-radio-inner-circle, .mat-mdc-radio-button.mat-mdc-warn .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple), .mat-mdc-radio-button.mat-mdc-warn.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple, .mat-mdc-radio-button.mat-mdc-warn:active .mat-mdc-radio-persistent-ripple {
  background-color: #f44336
}

.mat-mdc-radio-button.mat-mdc-radio-disabled.mat-mdc-radio-checked .mat-mdc-radio-outer-circle, .mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-outer-circle {
  border-color: rgba(0, 0, 0, .38)
}

.mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-ripple .mat-mdc-ripple-element, .mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-inner-circle {
  background-color: rgba(0, 0, 0, .38)
}

.mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-label-content {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-radio-button .mat-mdc-ripple-element {
  background-color: #000
}

.mat-mdc-select-value {
  color: @text
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, .42)
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-select-arrow {
  color: @text
}

.mat-mdc-select-panel {
  background: #fff
}

.mat-mdc-select-panel:not([class*=mat-mdc-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-select-panel .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
  background: rgba(0, 0, 0, .12)
}

.mat-mdc-form-field.mat-mdc-focused.mat-mdc-primary .mat-mdc-select-arrow {
  color: @primary
}

.mat-mdc-form-field.mat-mdc-focused.mat-mdc-accent .mat-mdc-select-arrow {
  color: #ff4081
}

.mat-mdc-form-field.mat-mdc-focused.mat-mdc-warn .mat-mdc-select-arrow {
  color: #f44336
}

.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: #f44336
}

.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-drawer-container {
  background-color: #fafafa;
  color: @text
}

.mat-mdc-drawer {
  background-color: #fff;
  color: @text
}

.mat-mdc-drawer.mat-mdc-drawer-push {
  background-color: #fff
}

.mat-mdc-drawer:not(.mat-mdc-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}

.mat-mdc-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, .12)
}

.mat-mdc-drawer-side.mat-mdc-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, .12);
  border-right: none
}

[dir=rtl] .mat-mdc-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, .12);
  border-right: none
}

[dir=rtl] .mat-mdc-drawer-side.mat-mdc-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, .12)
}

.mat-mdc-drawer-backdrop.mat-mdc-drawer-shown {
  background-color: rgba(0, 0, 0, .6)
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
  background-color: #ff4081
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-bar {
  background-color: rgba(255, 64, 129, .54)
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-ripple-element {
  background-color: #ff4081
}

.mat-mdc-slide-toggle.mat-mdc-primary.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
  background-color: @primary
}

.mat-mdc-slide-toggle.mat-mdc-primary.mat-mdc-checked .mat-mdc-slide-toggle-bar {
  background-color: rgba(63, 81, 181, .54)
}

.mat-mdc-slide-toggle.mat-mdc-primary.mat-mdc-checked .mat-mdc-ripple-element {
  background-color: @primary
}

.mat-mdc-slide-toggle.mat-mdc-warn.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
  background-color: #f44336
}

.mat-mdc-slide-toggle.mat-mdc-warn.mat-mdc-checked .mat-mdc-slide-toggle-bar {
  background-color: rgba(244, 67, 54, .54)
}

.mat-mdc-slide-toggle.mat-mdc-warn.mat-mdc-checked .mat-mdc-ripple-element {
  background-color: #f44336
}

.mat-mdc-slide-toggle:not(.mat-mdc-checked) .mat-mdc-ripple-element {
  background-color: #000
}

.mat-mdc-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa
}

.mat-mdc-slide-toggle-bar {
  background-color: rgba(0, 0, 0, .38)
}

.mat-mdc-slider-track-background {
  background-color: rgba(0, 0, 0, .26)
}

.mat-mdc-primary .mat-mdc-slider-track-fill, .mat-mdc-primary .mat-mdc-slider-thumb, .mat-mdc-primary .mat-mdc-slider-thumb-label {
  background-color: @primary
}

.mat-mdc-primary .mat-mdc-slider-thumb-label-text {
  color: #fff
}

.mat-mdc-primary .mat-mdc-slider-focus-ring {
  background-color: rgba(63, 81, 181, .2)
}

.mat-mdc-accent .mat-mdc-slider-track-fill, .mat-mdc-accent .mat-mdc-slider-thumb, .mat-mdc-accent .mat-mdc-slider-thumb-label {
  background-color: #ff4081
}

.mat-mdc-accent .mat-mdc-slider-thumb-label-text {
  color: #fff
}

.mat-mdc-accent .mat-mdc-slider-focus-ring {
  background-color: rgba(255, 64, 129, .2)
}

.mat-mdc-warn .mat-mdc-slider-track-fill, .mat-mdc-warn .mat-mdc-slider-thumb, .mat-mdc-warn .mat-mdc-slider-thumb-label {
  background-color: #f44336
}

.mat-mdc-warn .mat-mdc-slider-thumb-label-text {
  color: #fff
}

.mat-mdc-warn .mat-mdc-slider-focus-ring {
  background-color: rgba(244, 67, 54, .2)
}

.mat-mdc-slider:hover .mat-mdc-slider-track-background, .cdk-focused .mat-mdc-slider-track-background {
  background-color: rgba(0, 0, 0, .38)
}

.mat-mdc-slider-disabled .mat-mdc-slider-track-background, .mat-mdc-slider-disabled .mat-mdc-slider-track-fill, .mat-mdc-slider-disabled .mat-mdc-slider-thumb {
  background-color: rgba(0, 0, 0, .26)
}

.mat-mdc-slider-disabled:hover .mat-mdc-slider-track-background {
  background-color: rgba(0, 0, 0, .26)
}

.mat-mdc-slider-min-value .mat-mdc-slider-focus-ring {
  background-color: rgba(0, 0, 0, .12)
}

.mat-mdc-slider-min-value.mat-mdc-slider-thumb-label-showing .mat-mdc-slider-thumb, .mat-mdc-slider-min-value.mat-mdc-slider-thumb-label-showing .mat-mdc-slider-thumb-label {
  background-color: @text
}

.mat-mdc-slider-min-value.mat-mdc-slider-thumb-label-showing.cdk-focused .mat-mdc-slider-thumb, .mat-mdc-slider-min-value.mat-mdc-slider-thumb-label-showing.cdk-focused .mat-mdc-slider-thumb-label {
  background-color: rgba(0, 0, 0, .26)
}

.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing) .mat-mdc-slider-thumb {
  border-color: rgba(0, 0, 0, .26);
  background-color: transparent
}

.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing):hover .mat-mdc-slider-thumb, .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing).cdk-focused .mat-mdc-slider-thumb {
  border-color: rgba(0, 0, 0, .38)
}

.mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing):hover.mat-mdc-slider-disabled .mat-mdc-slider-thumb, .mat-mdc-slider-min-value:not(.mat-mdc-slider-thumb-label-showing).cdk-focused.mat-mdc-slider-disabled .mat-mdc-slider-thumb {
  border-color: rgba(0, 0, 0, .26)
}

.mat-mdc-slider-has-ticks .mat-mdc-slider-wrapper::after {
  border-color: rgba(0, 0, 0, .7)
}

.mat-mdc-slider-horizontal .mat-mdc-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)
}

.mat-mdc-slider-vertical .mat-mdc-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)
}

.mat-mdc-step-header.cdk-keyboard-focused, .mat-mdc-step-header.cdk-program-focused, .mat-mdc-step-header:hover {
  background-color: rgba(0, 0, 0, .04)
}

@media (hover: none) {
  .mat-mdc-step-header:hover {
    background: none
  }
}

.mat-mdc-step-header .mat-mdc-step-label, .mat-mdc-step-header .mat-mdc-step-optional {
  color: @text
}

.mat-mdc-step-header .mat-mdc-step-icon {
  background-color: @text;
  color: #fff
}

.mat-mdc-step-header .mat-mdc-step-icon-selected, .mat-mdc-step-header .mat-mdc-step-icon-state-done, .mat-mdc-step-header .mat-mdc-step-icon-state-edit {
  background-color: @primary;
  color: #fff
}

.mat-mdc-step-header .mat-mdc-step-icon-state-error {
  background-color: transparent;
  color: #f44336
}

.mat-mdc-step-header .mat-mdc-step-label.mat-mdc-step-label-active {
  color: @text
}

.mat-mdc-step-header .mat-mdc-step-label.mat-mdc-step-label-error {
  color: #f44336
}

.mat-mdc-stepper-horizontal, .mat-mdc-stepper-vertical {
  background-color: #fff
}

.mat-mdc-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, .12)
}

.mat-mdc-horizontal-stepper-header::before, .mat-mdc-horizontal-stepper-header::after, .mat-mdc-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, .12)
}

.mat-mdc-horizontal-stepper-header {
  height: 72px
}

.mat-mdc-stepper-label-position-bottom .mat-mdc-horizontal-stepper-header, .mat-mdc-vertical-stepper-header {
  padding: 24px 24px
}

.mat-mdc-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px
}

.mat-mdc-stepper-label-position-bottom .mat-mdc-horizontal-stepper-header::after, .mat-mdc-stepper-label-position-bottom .mat-mdc-horizontal-stepper-header::before {
  top: 36px
}

.mat-mdc-stepper-label-position-bottom .mat-mdc-stepper-horizontal-line {
  top: 36px
}

.mat-mdc-sort-header-arrow {
  color: #757575
}

.mat-mdc-tab-nav-bar, .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.mat-mdc-tab-group-inverted-header .mat-mdc-tab-nav-bar, .mat-mdc-tab-group-inverted-header .mat-mdc-tab-header {
  border-top: 1px solid rgba(0, 0, 0, .12);
  border-bottom: none
}

.mat-mdc-tab-label, .mat-mdc-tab-link {
  color: @text
}

.mat-mdc-tab-label.mat-mdc-tab-disabled, .mat-mdc-tab-link.mat-mdc-tab-disabled {
  color: rgba(0, 0, 0, .38)
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: @text
}

.mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, .38)
}

.mat-mdc-tab-group[class*=mat-mdc-background-] .mat-mdc-tab-header, .mat-mdc-tab-nav-bar[class*=mat-mdc-background-] {
  border-bottom: none;
  border-top: none
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-primary .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled) {
  background-color: rgba(197, 202, 233, .3)
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar {
  background-color: @primary
}

.mat-mdc-tab-group.mat-mdc-primary.mat-mdc-background-primary .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-primary.mat-mdc-background-primary .mat-mdc-ink-bar {
  background-color: #fff
}

.mat-mdc-tab-group.mat-mdc-accent .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-accent .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-accent .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-accent .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-accent .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-accent .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-accent .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-accent .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled) {
  background-color: rgba(255, 128, 171, .3)
}

.mat-mdc-tab-group.mat-mdc-accent .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-accent .mat-mdc-ink-bar {
  background-color: #ff4081
}

.mat-mdc-tab-group.mat-mdc-accent.mat-mdc-background-accent .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-accent.mat-mdc-background-accent .mat-mdc-ink-bar {
  background-color: #fff
}

.mat-mdc-tab-group.mat-mdc-warn .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-warn .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-warn .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-warn .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-warn .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-warn .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-warn .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-warn .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled) {
  background-color: rgba(255, 205, 210, .3)
}

.mat-mdc-tab-group.mat-mdc-warn .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-warn .mat-mdc-ink-bar {
  background-color: #f44336
}

.mat-mdc-tab-group.mat-mdc-warn.mat-mdc-background-warn .mat-mdc-ink-bar, .mat-mdc-tab-nav-bar.mat-mdc-warn.mat-mdc-background-warn .mat-mdc-ink-bar {
  background-color: #fff
}

.mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled) {
  background-color: rgba(197, 202, 233, .3)
}

.mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-header, .mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-links, .mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-header-pagination, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-header, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-links, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-header-pagination {
  background-color: @primary
}

.mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-label, .mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-link, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-label, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-link {
  color: #fff
}

.mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-label.mat-mdc-tab-disabled, .mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-link.mat-mdc-tab-disabled, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-label.mat-mdc-tab-disabled, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-link.mat-mdc-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-header-pagination-chevron {
  border-color: #fff
}

.mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-mdc-tab-group.mat-mdc-background-primary .mat-mdc-ripple-element, .mat-mdc-tab-nav-bar.mat-mdc-background-primary .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled) {
  background-color: rgba(255, 128, 171, .3)
}

.mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-header, .mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-links, .mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-header-pagination, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-header, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-links, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-header-pagination {
  background-color: #ff4081
}

.mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-label, .mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-link, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-label, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-link {
  color: #fff
}

.mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-label.mat-mdc-tab-disabled, .mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-link.mat-mdc-tab-disabled, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-label.mat-mdc-tab-disabled, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-link.mat-mdc-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-header-pagination-chevron {
  border-color: #fff
}

.mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-mdc-tab-group.mat-mdc-background-accent .mat-mdc-ripple-element, .mat-mdc-tab-nav-bar.mat-mdc-background-accent .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-label.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-label.cdk-program-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-mdc-tab-disabled), .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-link.cdk-program-focused:not(.mat-mdc-tab-disabled) {
  background-color: rgba(255, 205, 210, .3)
}

.mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-header, .mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-links, .mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-header-pagination, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-header, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-links, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-header-pagination {
  background-color: #f44336
}

.mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-label, .mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-link, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-label, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-link {
  color: #fff
}

.mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-label.mat-mdc-tab-disabled, .mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-link.mat-mdc-tab-disabled, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-label.mat-mdc-tab-disabled, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-link.mat-mdc-tab-disabled {
  color: rgba(255, 255, 255, .4)
}

.mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-header-pagination-chevron {
  border-color: #fff
}

.mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, .4)
}

.mat-mdc-tab-group.mat-mdc-background-warn .mat-mdc-ripple-element, .mat-mdc-tab-nav-bar.mat-mdc-background-warn .mat-mdc-ripple-element {
  background-color: rgba(255, 255, 255, .12)
}

.mat-mdc-toolbar {
  background: #f5f5f5;
  color: @text
}

.mat-mdc-toolbar.mat-mdc-primary {
  background: @primary;
  color: #fff
}

.mat-mdc-toolbar.mat-mdc-accent {
  background: #ff4081;
  color: #fff
}

.mat-mdc-toolbar.mat-mdc-warn {
  background: #f44336;
  color: #fff
}

.mat-mdc-toolbar .mat-mdc-form-field-underline, .mat-mdc-toolbar .mat-mdc-form-field-ripple, .mat-mdc-toolbar .mat-mdc-focused .mat-mdc-form-field-ripple {
  background-color: currentColor
}

.mat-mdc-toolbar .mat-mdc-form-field-label, .mat-mdc-toolbar .mat-mdc-focused .mat-mdc-form-field-label, .mat-mdc-toolbar .mat-mdc-select-value, .mat-mdc-toolbar .mat-mdc-select-arrow, .mat-mdc-toolbar .mat-mdc-form-field.mat-mdc-focused .mat-mdc-select-arrow {
  color: inherit
}

.mat-mdc-toolbar .mat-mdc-input-element {
  caret-color: currentColor
}

.mat-mdc-toolbar-multiple-rows {
  min-height: 64px
}

.mat-mdc-toolbar-row, .mat-mdc-toolbar-single-row {
  height: 64px
}

@media (max-width: 599px) {
  .mat-mdc-toolbar-multiple-rows {
    min-height: 56px
  }

  .mat-mdc-toolbar-row, .mat-mdc-toolbar-single-row {
    height: 56px
  }
}

.mat-mdc-tooltip {
  background: rgba(97, 97, 97, .9)
}

.mat-mdc-tree {
  background: #fff
}

.mat-mdc-tree-node, .mat-mdc-nested-tree-node {
  color: @text
}

.mat-mdc-tree-node {
  min-height: 48px
}

.mat-mdc-snack-bar-container {
  color: rgba(255, 255, 255, .7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-mdc-simple-snackbar-action {
  color: #ff4081
}
