@import "colors.less";

#link-details-dialog-content {
  .categories-values-progress-container {
    text-align: center;

    .ac-progress-center {
      left: 15px;
    }
  }
}

#audio-codes-device-dialog-content {

  .statusLabel {
    margin-top: 6px;
  }

  .multiple-radio-button {
    ac-radio-button {
      height: 100%;
      line-height: 25px;
    }
  }
}

#audio-codes-device-dialog-content,
#tenant-dialog-content,
#add-multiple-audio-codes-devices-dialog-content {

  #audio-codes-device-enable-https-connection-container {
    margin-top: 8px;
  }


  #first-connection-section-container {

    #audio-codes-device-enable-initial-connection-label {
      width: 225px !important;
    }

    ul.ac-device-dialog-supporting-products {
      border: 1px solid #ccc;
      position: relative;
      padding: 0;

      li.supported-product-item {
        border-bottom: 1px solid #ccc;
        padding: 4px 0 4px 8px;
        list-style-type: none;
      }

      li.supported-product-item:last-child {
        border-bottom: none;
      }
    }

  }

  .success-status {
    color: @greenStatus;
  }

  .fail-status {
    color: @redStatus;
  }

  .not-performed-status {
    color: @grayStatus;
  }
}

#software-upgrade-dialog-content {
  padding: 0 20px;
  width: 100%;

  .grid-container {
    border: 1px solid #ccc;
    height: 374px;
  }

  .mtc-type-container {
    display: flex;
    margin-bottom: 10px;

    :first-child {
      padding-right: 10px;
    }
  }
}
