@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import "font-awesome/less/font-awesome.less";
@import "./colors.less";


* {
  box-sizing: border-box;
  outline: none;
}

*:focus {
  outline: none !important;
}

html {
  line-height: 12px;
  color: @text;
}

.ac-disabled {
  pointer-events: none;
  opacity: 0.8;
  color: @disabledDark;

  & * {
    color: @disabledDark;
  }
}

ac-checkbox, label {
  &:hover {
    .mdc-checkbox__background {
      border-color: @primary !important;
    }
  }
}

.gradient-text {
  background: linear-gradient(90deg, #56AAFF 0.44%, #8636FF 100.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.header {
  font-size: 1.1rem;
  color: @lightGray;
}

.system-text {
  color: @text;
}

.information-key {
  color: @lightGray;
}

html {
  font-size: 13px !important;
  font-family: Inter, Helvetica Neue, sans-serif !important;
}

html, body {
  overflow: hidden;

  height: 100%;
  width: 100%;

  margin: 0;
  padding: 0;

  /* Prevent Chrome autofill coloring */

  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    -webkit-text-fill-color: @text;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 1000px inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
    zoom: 1.1666;
  }

  /* *************************** */

  .cdk-overlay-container {
    position: fixed;
    z-index: 90000;

    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 0;
    }
  }

  .mdc-menu-surface.mat-mdc-autocomplete-panel {
    margin: -4px 0 !important;
    padding: 0 !important;
  }

  .ac-page-actions, .ac-page-toolbar, .toolbar-actions-menu {
    display: flex;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  * {
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #90AABD;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  a {
    color: @primary;
    cursor: pointer;
  }

  .mat-mdc-radio-group, .ac-checkbox, .mat-mdc-slide-toggle {
    .mat-mdc-ripple {
      display: none !important;
    }
  }

  .fullScreen {
    background-color: white;
    height: auto !important;
    z-index: 9999999999;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    padding: 50px;

    .chartTitleActions {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  router-outlet {
    display: none;

    & + * {
      width: 100%;
    }
  }

  img {
    object-fit: contain;
  }

  label {
    font-weight: normal;
    margin-bottom: 0;
  }

  input, textarea { //chrome last update introduced black borders
    outline: none;
    border: 1px solid #d0d4d9;

    &:disabled {
      background-color: @disabled;
    }
  }

  input[type="text"],
  input[type="password"] {
    //height: 25px;
    //box-sizing: border-box !important;
  }

  [readonly] {
    color: grey;
  }

  [hidden] {
    display: none !important;
  }

  .ht-100 {
    height: 100%;
  }

  .fa.fa-circle {
    font-size: 13px;
    vertical-align: middle;
  }


  .prevent-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .visibilityHidden {
    visibility: hidden;
  }

  .textAlignCenter {
    text-align: center;
  }

  .displayNone {
    display: none!important;
  }

  .ac-disable {
    pointer-events: none;
  }

  .overflowHidden {
    overflow: hidden;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pointerEventsNone {
    pointer-events: none;
  }
}

.ac-drop-down.dynamic-tabs-dropdown {
  min-height: 2px;
}
