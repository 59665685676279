
body {
  line-height: normal;
}

.btn-primary {
  background-image: linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff337ab7",endColorstr="#ff265a88",GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #245580;
}

.btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
  text-shadow: 0 -1px 0 rgb(0 0 0 / 20%);
  box-shadow: inset 0 1px #ffffff26, 0 1px 1px #00000013;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;

  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
}


button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.table-condensed > tbody > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > thead > tr > th {
  padding: 5px;
}


