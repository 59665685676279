@import "colors.less";

.filter-view-panel {
  width: 210px;
}

.details-view-container {
  .chart-div-container {
    height: 420px;
    width: 300px;
  }
}

.alarms-summary-details-pane {
  overflow: hidden;

  .alarm-details-list {
    list-style-type: none;
    margin-top: 10px;
    padding-left: 0;

  }
}

.strikethrough-line {
  text-decoration: line-through;
}

.severity-journal {
  color: @purpleStatus;
}

